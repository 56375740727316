@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --body-main: 1rem;
    --body-secondary: 0.625rem;
    --body-label: 0.625rem;
    --body-secondary-label: 0.625rem;
    --color-main: #1A4656;
    --color-dark: #313131;
    --color-secondary: #A6A6A6;
    --app-secondary: #E5F5F9;
}


*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
    width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #232323;
    text-align: left;
    background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

a {
    color: #007bff;
    text-decoration: none !important;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}


a:hover {
    color: #0056b3;
    text-decoration: underline;
}

h1,
.h1 {
    font-size: 1.500rem;
    font-weight: 500;
}

h2,
.h2 {
    font-size: 1.250rem;
    font-weight: 600;
}

h3,
.h3 {
    font-size: 1rem;
    font-weight: 600;
}

h4,
.h4 {
    font-size: 0.625rem;
    font-weight: 600;
}

h5,
.h5 {
    font-size: 0.375rem;
    font-weight: 700;
}

h6,
.h6 {
    font-size: 0.25rem;
    font-weight: 600;
}

.pointer {
    cursor: pointer;
}

.mr-0 {
    margin-right: 0px;
}

.mr-10 {
    margin-right: 10px;
}

.text-secondary {
    color: var(--color-secondary);
}

.colorRed {
    color: rgb(255,35,64);
}

.colorSkyBlue {
    color: rgb(36,212,247);
}

.container {
    max-width: 95%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

.titleBlock,
.feedTitleBlock,
.sectionTitleBlock,
.cardTitle {
    display: flex;
    align-items: center;
}

.titleBlock {
    padding: 20px 0 30px 0;
}

.titleBlock h1 {
    margin-bottom: 0;
    color: var(--color-main);
    font-weight: 600;
}

.feedTitleBlock {
    margin-bottom: 20px;
}

.feedTitleBlock h3 {
    margin-bottom: 0;
    color: var(--color-dark);
    font-weight: 500;
}

.sectionTitleBlock h3 {
    margin-bottom: 0;
    color: var(--color-main);
}

.app-icon {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
}

.icon-16 {
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
}

.icon-24 {
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
}

.icon-32 {
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
}

.icon-72 {
    width: 216px;
    height: 72px;
    background-size: 72px 72px;
}

.chevron-left {
    background-image: url(../images/icon-chevron-left.svg);
}

.icon-app {
    background-image: url(../images/icon-app.svg);
}

.icon-app-text {
    background-image: url(../images/ic_app_logo_text.svg);
}

.icon-math {
    background-image: url(../images/icon-math.svg);
}

.icon-analysis {
    background-image: url(../images/icon-analysis.svg);
}

.icon-content-validation {
    background-image: url(../images/icon-tick-circle.svg);
}

.icon-assessment {
    background-image: url(../images/icon-assessment.svg);
}

.icon-chat {
    background-image: url(../images/icon-chat.svg);
}

.icon-maximize {
    background-image: url(../images/icon-maximize.svg);
}

.contentBlock {
    padding: 10px;
}

.date {
    font-weight: 400;
}

.captureImg {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    display: flex;
}

.maximizeIconBlock {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    z-index: 1;
}

.captureImg img {
    width: 100%;
}

.contentBlock .captureImg {
    width: 100%;
    overflow: hidden;
    max-height: 300px;
    max-width: 300px;
}

.imgContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.imgContainer img {
    max-height: 300px;
    max-width: 300px;
    object-fit: contain;
}

.card {
    border-radius: 8px;
    border: 1px solid var(--app-secondary);
    padding: 15px;
}

.cardTitle {
    font-weight: 400;
    color: var(--color-main);
    background-color: #f0f8ff;
    padding: 20px;
}

.cardTitle h3 {
    margin-bottom: 0;
    font-weight: 400;
}

.flexDisplay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cardBody {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 20px auto;
    overflow: hidden;
}

.cardBody:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.chatWrap {
    display: flex;
    align-items: flex-end;
    margin-bottom: 26px;
}

.msgAvatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
}

.msgAvatar img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
}

.msgRight {
    flex-direction: row-reverse;
}

.msgLeft .msgAvatar {
    background: #B7D0FF;
    margin-right: 10px;
}

.msgRight .msgAvatar {
    background: #F7BA0F;
    margin-left: 10px;
}

.msgBubble {
    max-width: calc(100% - 50px);
    padding: 10px;
    border-radius: 15px;
    font-size: 0.875rem;
    font-weight: 400;
    position: relative;
}

.msgLeft .msgBubble {
    background: #E6E5EB;
}

.msgRight .msgBubble {
    background: var(--color-main);
    color: #fff;
}
.msgLeft .msgBubble:before,
.msgRight .msgBubble:before{
    content: "";
    width: 20px;
    height: 24px;
    display: inline-block;
    position: absolute;
    bottom: -9px;
}
.msgLeft .msgBubble:before {    
    left: -5px;
    background: url(../images/tail.svg) no-repeat 0 0;
}
.msgRight .msgBubble:before {    
    right: -5px;
    
    background: url(../images/tail-dark.svg) no-repeat 0 0;
}


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* for responsiveness */
@media (min-width: 475px) {

    .msgBubble {
        max-width: calc(100% - 30px);
    }

    .msgLeft .msgBubble,
    .msgRight .msgBubble {
        font-size: 0.875rem;
    }

    .card {
        width: 100%;
        padding: 10px;
    }

    .cardTitle {
        text-align: center;
    }

    .cardBody {
        font-size: 0.875rem;
    }

    .contentBlock .captureImg {
        width: 100%;
        overflow: hidden;
        max-height: 400px;
        max-width: 400px;
    }
    
    .imgContainer img {
        max-height: 400px;
        max-width: 400px;
        object-fit: contain;
    }

    .msgAvatar {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
    }
    
    .msgAvatar img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
    }

    .contentBlock {
        padding: 10px;
    }

    .chatWrap {
        display: flex;
        align-items: flex-end;
        margin-bottom: 26px;
    }
    
    .msgLeft .msgAvatar {
        background: #B7D0FF;
        margin-right: 5px;
    }
    
    .msgRight .msgAvatar {
        background: #F7BA0F;
        margin-left: 5px;
    }

    svg {
        max-width: 100%;
    }

    .mjx-container {
        max-width: 100%; 
        overflow-x: auto; 
        white-space: nowrap; 
    }

    .mjx-container svg {
        display: inline-block; 
        max-width: none; 
    }

}

@media (min-width: 640px) {

    .msgBubble {
        max-width: calc(100% - 30px);
    }

    .msgLeft .msgBubble,
    .msgRight .msgBubble {
        font-size: 0.875rem;
    }

    .card {
        width: 100%;
        padding: 10px;
    }

    .cardTitle {
        text-align: center;
    }

    .cardBody {
        font-size: 0.875rem;
    }

    .contentBlock .captureImg {
        width: 100%;
        overflow: hidden;
        max-height: 450px;
        max-width: 450px;
    }
    
    .imgContainer img {
        max-height: 450px;
        max-width: 450px;
        object-fit: contain;
    }

    .msgAvatar {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
    }
    
    .msgAvatar img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
    }

    .contentBlock {
        padding: 10px;
    }

    .chatWrap {
        display: flex;
        align-items: flex-end;
        margin-bottom: 26px;
    }
    
    .msgLeft .msgAvatar {
        background: #B7D0FF;
        margin-right: 5px;
    }
    
    .msgRight .msgAvatar {
        background: #F7BA0F;
        margin-left: 5px;
    }

    svg {
        max-width: 100%;
    }

    .mjx-container {
        max-width: 100%; 
        overflow-x: auto; 
        white-space: nowrap; 
    }

    .mjx-container svg {
        display: inline-block; 
        max-width: none; 
    }

}

@media (min-width: 768px) {

    .container {
        max-width: 95%;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: auto;
        margin-left: auto;
    }

    h1,
    .h1 {
        font-size: 1.750rem;
        font-weight: 500;
    }

    h2,
    .h2 {
        font-size: 1.250rem;
        font-weight: 500;
    }

    h3,
    .h3 {
        font-size: 1rem;
        font-weight: 500;
    }

    .cardBody {
        margin: 10px auto;
    }

    .msgBubble {
        max-width: calc(100% - 30px);
    }

    .contentBlock .captureImg {
        width: 100%; 
        overflow: hidden;
        max-height: 500px; 
    }
    
    .imgContainer img {
        max-height: 500px;
        max-width: 500px;
        object-fit: contain;
    }

    .msgAvatar {
        width: 20px;
        height: 20px;
    }

    .mjx-container {
        max-width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    .mjx-container svg {
        display: inline-block;
    }
}

@media (min-width: 1024px) {

    .container {
        max-width: 95%;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: auto;
        margin-left: auto;
    }

    h1,
    .h1 {
        font-size: 1.750rem;
        font-weight: 500;
    }

    h2,
    .h2 {
        font-size: 1.250rem;
        font-weight: 500;
    }

    h3,
    .h3 {
        font-size: 1rem;
        font-weight: 500;
    }

    .cardBody {
        margin: 10px auto;
    }

    .msgBubble {
        max-width: calc(100% - 30px);
    }

    .contentBlock .captureImg {
        width: 100%; 
        overflow: hidden;
        max-height: 550px; 
    }
    
    .imgContainer img {
        max-height: 550px;
        max-width: 550px;
        object-fit: contain;
    }

    .msgAvatar {
        width: 20px;
        height: 20px;
    }

    .mjx-container {
        max-width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    .mjx-container svg {
        display: inline-block;
    }
}

@media (min-width: 1280px) {

    .container {
        max-width: 1280px;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: auto;
        margin-left: auto;
    }

    h1,
    .h1 {
        font-size: 1.750rem;
        font-weight: 500;
    }

    h2,
    .h2 {
        font-size: 1.250rem;
        font-weight: 500;
    }

    h3,
    .h3 {
        font-size: 1rem;
        font-weight: 500;
    }

    .cardBody {
        margin: 10px auto;
    }

    .msgBubble {
        max-width: calc(100% - 30px);
    }

    .contentBlock .captureImg {
        width: 100%; 
        overflow: hidden;
        max-height: 600px; 
    }
    
    .imgContainer img {
        max-height: 600px;
        max-width: 600px;
        object-fit: contain;
    }

    .msgAvatar {
        width: 20px;
        height: 20px;
    }

    .mjx-container {
        max-width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    .mjx-container svg {
        display: inline-block;
    }
}

@media (min-width: 1536px) {

    .container {
        max-width: 1280px;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: auto;
        margin-left: auto;
    }

    h1,
    .h1 {
        font-size: 2.625rem;
        font-weight: 500;
    }

    .contentBlock .captureImg {
        width: 100%;
        overflow: hidden;
        max-height: 650px;
        max-width: 650px;
    }
    
    .imgContainer {
        width: 100%;
        height: 100%;
    }
    
    .imgContainer img {
        max-height: 650px;
        max-width: 650px;
        object-fit: contain;
    }
}

